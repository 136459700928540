(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  var canvas = document.createElement('canvas');
  var body = document.querySelector('body');

  if (canvas.getContext && canvas.getContext('2d')) {
    body.classList.add('webp');
    return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  body.classList.add('no-webp');
  return false;
};

},{}],2:[function(require,module,exports){
"use strict";

var _webPSupport = require("../../default/js/webPSupport.js");

var _webPSupport2 = _interopRequireDefault(_webPSupport);

var _drawer = require("./drawer");

var _drawer2 = _interopRequireDefault(_drawer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

document.addEventListener('DOMContentLoaded', function () {
  var body = document.querySelector('body');
  var navItems = body.querySelectorAll('.header__link');
  /** Webp support */

  (0, _webPSupport2.default)();
  /** Toggle navigation */

  (0, _drawer2.default)(body);

  if (navItems.length) {
    navItems.forEach(function (item) {
      item.addEventListener('click', function (e) {
        navItems.forEach(function (el) {
          return el.classList.remove(el.classList[0] + "--active");
        });
        e.target.classList.add(e.target.classList[0] + "--active");
      });
    });
  }
});

},{"../../default/js/webPSupport.js":1,"./drawer":3}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

exports.default = function (body) {
  var toggle = body.querySelector('.header__toggle');
  var drawer = body.querySelector('.drawer');

  if (toggle && drawer) {
    toggle.addEventListener('click', function () {
      toggle.classList.toggle(toggle.classList[0] + "--active");
      drawer.classList.add(drawer.classList[0] + "--active");
      body.classList.add('fixed');
      var closeButtons = [drawer.querySelector('.drawer__right'), drawer.querySelector('.drawer__close')].concat(_toConsumableArray(drawer.querySelectorAll('.drawer__link')));
      closeButtons.forEach(function (btn) {
        if (btn) {
          btn.addEventListener('click', hideDrawer);
        }
      });
    });
  }

  var hideDrawer = function hideDrawer() {
    toggle.classList.remove(toggle.classList[0] + "--active");
    drawer.classList.remove(drawer.classList[0] + "--active");
    body.classList.remove('fixed');
  };
};

},{}]},{},[2]);
